import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Fade from "react-reveal/Fade"
import Buttons from "../../components/common/button.js"
import ColorCards from "../../components/common/Color-card/ColorCards.js"
import CTA from "../../components/common/CTA.js"
import FeatureCards from "../../components/common/Feature-card/FeatureCards.js"
import Frame from "../../components/common/frame.js"
import MarqueeImages from "../../components/common/MarqueeImages/MarqueeImages.js"
import ScrollCardNavTabs from "../../components/common/Scrolling-container/ScrollCardNavTabs.js"
import VerticalTabs from "../../components/common/VerticalTabComponent/VerticalTabs.js"
import DoubleNavbar from "../../components/DoubleNavbar.js"
import "../../styles/pages/it-25.scss"
import { ScatterPlot, SliderTestimonial } from "../index.js"

const IT25 = ({ data }) => {
  const { seo, newField, ctaBoxes } = data.SuperOps.pages[0]
  const heroSection = newField[0]
  const benefitsData = newField[1]
  const marqueeData = newField[2]
  const itTeamCards = newField[3]
  const faqData = newField[5]
  const testimonialData = newField[6]
  const integration1 = newField[9]
  const integration2 = newField[10]
  const introImage = newField[11].image[0].url
  const scatterPlot = newField[12]

  return (
    <div className="it-25">
      <Frame className="outer-container" seo={seo} ogType="website" bigFooter>
        <DoubleNavbar navType="double-nav" />

        <section className="it-hero-container">
          <div className="hero-section d-flex align-items-center text-center flex-column">
            <div className="content-wrapper Layout-container-big">
              <div className="tag-c down-to-uo-1">
                <p className="tag">{heroSection.tag}</p>
              </div>

              <div className="title-wrapper down-to-up-2">
                {parse(heroSection.heroHeading.html)}
              </div>

              <p className="sub-title down-to-up-2">
                {heroSection.heroContent.text}
              </p>

              <div className="hero-points down-to-up-3">
                {heroSection.lists.map((point, idx) => {
                  return (
                    <div className="point" key={point}>
                      <img
                        src={newField[0].images[0].url}
                        className="twinkle-icon"
                      />
                      <p className="text">{point}</p>
                    </div>
                  )
                })}
              </div>

              <div className="buttons-wrapper">
                <div className="hero-buttons prelative d-flex gap-16 flex-wrap">
                  <Buttons
                    theme="primary-new down-to-up-4"
                    link={heroSection.primaryButtonLink}
                    text={heroSection.primaryButtonText}
                  />
                  <Buttons
                    theme="primary-new white-new  down-to-up-4"
                    link={heroSection.secondaryButtonLink}
                    text={heroSection.secondaryButtonText}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="intro-img-wrapper container w-100 mx-auto down-to-up-5">
          <img className="w-100" src={introImage} alt="home-intro-image" />
        </section>

        <section className="marquee-images-container">
          <MarqueeImages
            heading={marqueeData.title[0]}
            images={marqueeData.image}
          />
        </section>

        <section className="benefit-cards-parent">
          <div className="Layout-container-big mx-auto">
            <Fade bottom duration={1000}>
              <div className="heading-wrapper">
                {parse(benefitsData.content[0]?.html)}
              </div>
            </Fade>
            <FeatureCards
              title={benefitsData.heading}
              description={benefitsData?.text}
              image={benefitsData?.image}
              linkName={benefitsData?.subtext}
              link={benefitsData?.links}
              cardsPerRow={3}
              showBgColorImgOnHover
            />
          </div>
        </section>

        <section className="integration-container">
          <Fade bottom delay={150} duration="1000">
            <div className="Layout-container-big d-flex justify-content-center flex-wrap gap-40 align-items-center">
              {parse(integration1?.content[0]?.html)}

              <Buttons
                theme={`secondary-new demo-btn-left`}
                text={integration1.title[0]}
                link={integration1.links[0]}
              />
            </div>
          </Fade>
        </section>

        <section className="scrolling-tabs-container">
          <ScrollCardNavTabs
            data={newField[7]}
            isPlayOnViewPort
            containerClass
            isImage={["no"]}
            isVideo={["Yes"]}
          />
        </section>

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[0]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            isSecondaryBtn
            newDesign
          />
        </section>

        <div className="Layout-container-big mx-auto it-ops-card-container">
          <Fade bottom duration={1000}>
            <div className="heading">{parse(itTeamCards.content[0].html)}</div>
          </Fade>

          <ColorCards
            title={itTeamCards?.title}
            description={itTeamCards?.subtext}
            image={itTeamCards?.image}
            color={itTeamCards?.links}
          />
        </div>

        <VerticalTabs data={faqData} containerClass />

        <section className="integration-container ic2">
          <Fade bottom delay={150} duration="1000">
            <div className="Layout-container-big d-flex justify-content-center flex-wrap gap-40 align-items-center">
              <Fade bottom duration={1000}>
                {parse(integration2?.content[0]?.html)}
              </Fade>

              <Buttons
                theme={`secondary-new demo-btn-left`}
                text={integration2.title[0]}
                link={integration2.links[0]}
              />
            </div>
          </Fade>
        </section>

        <ScatterPlot
          data={{
            image: scatterPlot.image,
            cardContent: scatterPlot.content,
            cardText: scatterPlot.text,
            cardSubText: scatterPlot.subtext,
            cardLinks: scatterPlot.links,
          }}
        />

        <Fade bottom delay={150} duration="1000">
          <SliderTestimonial testimonial={testimonialData} />
        </Fade>

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[1]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            newDesign
            isSecondaryBtn
          />
        </section>
      </Frame>
    </div>
  )
}

export default IT25

export const query = graphql`
  query {
    SuperOps {
      pages(where: { title: "IT-2025" }) {
        title
        linkTitle
        pageImages {
          url
        }
        navigationBlock {
          name
          slug
          isDropdown
        }
        seo {
          title
          description
          keywords
          image {
            url
          }
        }
        navigationBlock {
          colors {
            hex
          }
          where
          dropdownHeader
          navigationLinks {
            heading
            subText
            link
            image {
              url
            }
            childFooter
            childFooterLink
            featureNavigationLinks {
              heading
              subText
              link
              icon {
                url
              }
              iconBgColor {
                css
              }
              childHeader
            }
          }
        }
        ctaBoxes {
          type
          theme
          customBackgroundColor
          heading {
            html
          }
          description {
            html
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          card {
            cardName
            title
            subtext
            slug
          }
        }
        newField {
          ... on SuperOps_Hero {
            lists
            tag
            heroHeading: heading {
              html
            }
            heroContent: content {
              text
              html
            }
            images {
              url
            }
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
          }
          ... on SuperOps_Testimonial {
            peopleList {
              position
              name
              title
              picture {
                url
              }
              cardBgColor
              thumbnail {
                url
              }
            }
            testimonialType
            secondaryBtnText
            secondaryBtnLink
            testimonialTitle
            testimonialImage {
              url
            }
          }
          ... on SuperOps_Card {
            title
            heading
            cardName
            cardDescription
            slug
            links
            text
            content {
              html
            }
            subtext
            image {
              url
            }
          }

          ... on SuperOps_FaqComponent {
            __typename
            faqTitle: title {
              html
            }
            faqHeading: heading
            faqDescription: description
            ctaText {
              text
              html
            }
            faqType
            buttonText: text
            link
            faqs {
              illustrationPattern
              question
              answerrt {
                html
                text
              }
              description
              linkText
              slug
              images {
                url
                fileName
              }
            }
          }
        }
      }
    }
  }
`
